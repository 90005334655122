.rotate-warning {
    position: absolute;
    // inset: 0;
    height: 100%;
    width: 100%;

    font-size: 30px;
    color: red;
    z-index: 1000;
    opacity: 0.7;
    text-align: center;
    background-color: white;
}

@media screen and (orientation:portrait) {
    .screen-rotate-warning {
        display: block;
    }
}

@media screen and (orientation:landscape) {
    .screen-rotate-warning {
        display: none;
    }
}