.game-result-container {
    background-color: black;
    // opacity: 0.8;
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 100%;
    // width: 100%;
    // position: fixed;
    font-size: 5vw;
}