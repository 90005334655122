.move-buttons {
    display: none;

    .control {
        position: absolute;
        top: 60%;
        text-align: center;
        z-index: 1000;
    }

    .direction {
        left: 2.5%;
    }

    .fire {
        right: 2.5%;
        background-color: darkturquoise;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: darkcyan;
        }
    }

    button {
        color: black;
        background-color: white;
        opacity: 0.6;
        font-weight: bold;
        font-family: Pixeloid;
    }
}

@media screen and (orientation:landscape) {
    .move-buttons {
        display: block;
    }
}