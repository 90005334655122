.game-start-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    background-color: black;
    height: 100%;
    // overflow: hidden;
    // position: relative;
    width: 100%;
    position: fixed;
    
    .title {
        width: 50%;
        height: auto;
        padding: 4vw 0;
    }

    .game-start-button-container {
        display: flex;
        flex-direction: column;
    }

    .game-start-button-content-container {
        display: flex;
        align-items: center;
        margin-left: -5vw;
        margin-bottom: 1vw;
    }

    .select-item-tank1, .select-item-tank2 {
        margin-right: 2vw;
        width: 3vw;
        height: 3vw;
        background-size: cover;
        animation: tank-walk 0.1s infinite alternate;
    }

    .select-item-tank1 {
        visibility: visible;
    }

    .select-item-tank2 {
        visibility: hidden;
    }

    .stage-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .stage-bg-up, .stage-bg-down {
            background-color: #737573;
            animation: stage-bg-grown 0.5s forwards;
        }

        .stage-text {
            position: absolute;
            background-color: #737573;
            z-index: 1;
            font-size: 2.5vw;
            width: 100%;
            top: 47%;
            text-align: center;
            opacity: 0;
            animation: stage-text-show 0.5s forwards;
            animation-delay: 0.5s;
        }
    }
}

@keyframes stage-text-show {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes stage-bg-grown {
    from {height: 0%;}
    to {height: 50%;}
}

@keyframes tank-walk {
    0% {
        background-image: url('../../assets/scene/select-item-tank.png');
    }
    100% {
        background-image: url('../../assets/scene/select-item-tank-1.png');
    }
}

