
.control-panel-container {
    z-index: 1;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .fire-handler {
        right: 2.5%;
        background-color: darkturquoise;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: darkcyan;
        }

        &:active {
            transform: scale(0.8);
        }
    }
}

@media screen and (orientation:portrait) {
    .control-panel-container {
        display: none;
    }
}