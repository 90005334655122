.world-container {
    // border: 4px solid white;
    // position: absolute;
    // inset: 0;
    
    display: flex;
    // position: fixed;
    // width: 100%;
    // height: 63%;
    // height: auto;


    // .control-panel-left-container {
    //     background-color: red;
    //     flex: 1;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    // .fire-handler {
    //     right: 2.5%;
    //     background-color: darkturquoise;
    //     width: 80px;
    //     height: 80px;
    //     border-radius: 50%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     cursor: pointer;

    //     &:hover {
    //         color: darkcyan;
    //     }

    //     &:active {
    //         transform: scale(0.8);
    //     }
    // }
    
    .playground-container {
        // border: 4px solid white;
        position: relative;
        // max-width: 70%;
        aspect-ratio: 2/1;
        // margin: 0 auto;
        flex: 5;
    }
}

// @media screen and (orientation:portrait) {
//     .world-container {
//         display: none;
//     }
// }